import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useMap } from 'usehooks-ts';

import fetchForecastOutlook from 'common/api/forecastOutlook';
import type { UserFavorites } from 'types/userFavorites';
import type { DaySelectorData, DaySelectorOverview } from 'types/daySelector';
import { useForecastDaysAllowed } from 'utils/forecastDaysAllowed';

interface ForecastOutlookContextProps {
  forecasts: DaySelectorData | undefined;
  requestXForecasts: Function;
}

const ONE_SECOND = 1;
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;

export const ForecastOutlookContext = createContext({} as ForecastOutlookContextProps);

const trimOverviewData = (overview: DaySelectorOverview, numberOfDays: number = 16) => {
  const { days, surf } = overview;
  if (!surf || !days) return { ...overview };

  const trimmedDays = days.slice(0, numberOfDays);

  const lastTimestamp = surf[0].timestamp + numberOfDays * ONE_DAY;

  const trimmedSurf = surf.filter(
    ({ timestamp }: { timestamp: number }) => timestamp <= lastTimestamp,
  );

  return {
    ...overview,
    days: trimmedDays,
    surf: trimmedSurf,
  };
};

const sliceIntoChunks = (arr: any[], chunkSize: number): Array<any[]> => {
  const chunked = [];

  const sortedSpotIds = arr.sort((a, b) =>
    a?._id.localeCompare(b?._id, undefined, {
      numeric: true,
      sensitivity: 'base',
    }),
  );

  for (let i = 0; i < sortedSpotIds.length; i += chunkSize) {
    const chunk = sortedSpotIds.slice(i, i + chunkSize);
    chunked.push(chunk);
  }
  return chunked;
};

interface ForecastOutlookContextProviderProps {
  children: React.ReactNode;
  hasCoreForecastPermissions: boolean;
  surfHeightUnit: string | undefined;
}

export const ForecastOutlookContextProvider = ({
  children,
  hasCoreForecastPermissions,
  surfHeightUnit,
}: ForecastOutlookContextProviderProps) => {
  const [forecasts, setForecasts] = useState<DaySelectorData | undefined>(undefined);
  const [units, setUnits] = useState({ waveHeight: surfHeightUnit || 'ft' });
  const [forecastCache, forecastCacheAction] = useMap<string, DaySelectorOverview>();

  const { forecastDaysAllowed } = useForecastDaysAllowed({
    type: 'home',
    isEntitled: hasCoreForecastPermissions,
  });

  const startDate = useMemo(() => {
    const newDate = new Date();
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    return newDate;
  }, []);

  useEffect(() => {
    setForecasts({
      associated: { units },
      data: {
        overview: Array.from(forecastCache.values()),
      },
    });
  }, [forecastCache, units]);

  const requestXForecasts = useCallback(
    (selectedFavorites: UserFavorites, numForecasts: number): void => {
      const spotsToRequest = selectedFavorites
        ?.filter((fav) => forecastCache.get(fav._id) === undefined)
        .slice(0, numForecasts);

      if (!spotsToRequest) return;

      spotsToRequest.forEach((fav) =>
        forecastCacheAction.set(fav._id, {
          spotId: fav._id,
          spotName: fav.name,
          loading: true,
          surf: null,
          days: null,
        }),
      );

      const forecastRequests = sliceIntoChunks(spotsToRequest, 5).map(
        (chunkOfSpots): Promise<DaySelectorData> => {
          const spotIds = chunkOfSpots.map((fav) => fav._id);
          return fetchForecastOutlook(spotIds, {
            units: { surfHeight: units.waveHeight },
          }) as Promise<DaySelectorData>;
        },
      );

      Promise.all(forecastRequests)
        .then((res: Array<DaySelectorData>) => {
          res.forEach((individualRes: DaySelectorData) => {
            if (individualRes?.associated?.units) setUnits(individualRes?.associated.units);
            individualRes.data.overview.forEach((spotOverview) =>
              forecastCacheAction.set(spotOverview.spotId, {
                ...trimOverviewData(spotOverview, forecastDaysAllowed),
                spotName: selectedFavorites.find((fav) => fav._id === spotOverview.spotId)?.name,
              }),
            );
          });
        })
        .catch((err) => {
          spotsToRequest.forEach((fav) =>
            forecastCacheAction.set(fav._id, {
              spotId: fav._id,
              error: err.message,
              surf: null,
              days: null,
            }),
          );
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forecastCacheAction, forecastCache, startDate],
  );

  const contextValue = useMemo(
    () => ({ forecasts, requestXForecasts }),
    [forecasts, requestXForecasts],
  );

  return (
    <ForecastOutlookContext.Provider value={contextValue}>
      {children}
    </ForecastOutlookContext.Provider>
  );
};
